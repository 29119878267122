<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="reports"
      :loading="status.loading"
      :items-per-page="50"
      class="elevation-1"
      @click:row="clickedRow"
    >
      <template v-slot:[`item.tempo`]="{ item }">
        <v-chip outlined>{{ getTempo(item.tempo) }}</v-chip>
      </template>
      <template v-slot:[`item.createdAt`]="{ item }">
        {{ humanDate(item.createdAt, "dd MMM yyyy - HH:mm") }}
      </template>
      <template v-slot:[`item.lastSent`]="{ item }">
        {{ humanDate(item.lastSent, "dd MMM yyyy - HH:mm") }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "ReportList",

  data() {
    return {
      headers: [
        { text: this.$t("report.fields.name"), value: "name" },
        { text: this.$t("report.fields.createdAt"), value: "createdAt" },
        { text: this.$t("report.fields.tempo"), value: "tempo" },
        { text: this.$t("report.fields.lastSent"), value: "lastSent" },
      ],
    };
  },
  computed: {
    ...mapState("users", [
      "status",
      "currentUser",
      "userCompany",
      "hasCompany",
    ]),
    ...mapState("reports", { status: "status", reports: "reports" }),
  },
  methods: {
    ...mapActions("reports", ["getReports", "setCurrentReport"]),

    async clickedRow(item) {
      await this.setCurrentReport(item);

      if (this.permitted("Report.Update"))
        this.$router.push(`/report/${item.reportId}`);
    },

    getTempo(tempo) {
      return this.$t(`report.tempo.${tempo}`);
    },
  },

  created() {
    this.getReports();
  },
};
</script>

<style>
</style>